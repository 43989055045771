import React from "react"
import MarkdownContent from "../MarkdownContent";
import './question-bubble.scss'
import Mailto from "react-protected-mailto"

export default ({ title, description, phone, email, children }) => (
    <div className="arrow-box">
        <h2>{title}</h2>
        <MarkdownContent content={description} />
        <ul className="question-data">
            <li className="data-item">
                <i className="fa fa-phone" aria-hidden="true"></i><Mailto className="phone" tel={phone} />
            </li>
            <li className="data-item">
                <i className="fa fa-envelope" aria-hidden="true"></i><Mailto className="email" email={email} />
            </li>
        </ul>
        { children }
    </div>
)