import React from "react";
import Img from "gatsby-image"
import { withTranslation } from "react-i18next";
import './poolusage.scss'
import SocialShare from "../SocialShare"
import QuestionBubble from "../QuestionBubble"
import Heading from "../Heading"

class PoolUsage extends React.Component {

    render() {

        const { data, content} = this.props

        return(
            <div id="poolusage" class="blog-area blog-no-sidebar pt-100 pb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 blog-post-item ml-auto mr-auto">
                            <div class="blog-wrapper blog-details">
                                <div class="blog-content">
                                    <Heading title={data.pageTitle} subtitle={data.pageSubtitle} className="mb-50" />
                                    <div dangerouslySetInnerHTML={{__html: content}} />
                                    <div class="blog-img col-xs-12">
                                        <Img sizes={data.poolImage.childImageSharp.fluid} />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-40 full-width">
                                <div class="col-md-6">
                                    <blockquote class="mb-50">
                                        {data.blockquote}
                                    </blockquote>
                                </div>
                                <div class="col-md-6">
                                    <QuestionBubble 
                                        title={data.questionTitle} 
                                        description={data.questionDescription} 
                                        phone={data.questionPhone}  
                                        email={data.questionEmail} />
                                </div>
                            </div>
                            <div class="common-tag-and-next-prev">
                                <SocialShare title={data.title} hashtags="sports,pool,swimming"/>
                            </div>
                        </div>
                    </div>                   
                </div>
            </div>
        )
    }
}
export default withTranslation()(PoolUsage)