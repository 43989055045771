import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import PoolUsage from "../components/PoolUsage/index.js"

const PoolUsagePage = ({ pageContext: { localeData }, data }) => {

    const {frontmatter: fields} = data.page
    const {childImageSharp: bgImage} = data.bgImage

    return (
      <Layout localeData={localeData} title={fields.title}>
          <BookingLine localeData={localeData}/>
          <Breadcrumb slug={fields.slug} title={fields.title} bgImage={bgImage} bgOffset="-80" />
          <PoolUsage data={fields} content={data.page.html} />
      </Layout>
    );
};
export default withI18next()(PoolUsagePage);

export const query = graphql`
  query($id: String!) {
    page: markdownRemark(id: { eq: $id })
    {
      html
      frontmatter {
        slug
        title
        pageTitle
        pageSubtitle
        questionTitle
        questionDescription
        questionPhone
        questionEmail
        blockquote
        description
        poolImage{
            childImageSharp {
            fluid(maxWidth: 770, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    bgImage: file(relativePath: { eq: "bg/PoolUsage_bg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }`